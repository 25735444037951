<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('course_outcomes')" :isNewButton="checkPermission('course_index')" :isColumns="true"
					@new-button-click="createFormShow()"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('course_outcomes')"
					@filter-div-status="datatable.filterStatus = $event">
					<template v-slot:columns>
						<div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
							<b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
								:unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
								{{ column.label }}
							</b-form-checkbox>
						</div>
					</template>
				</HeaderMobile>
			</template>
			<datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
				<b-row>
                    <b-col cols="12">
                        <b-col cols="4">
                            <b-form-group :label="$t('status')">
                                <status-selectbox
                                    v-model="datatable.queryParams.filter.status"
                                />
                            </b-form-group>
                        </b-col>

                    </b-col>

				</b-row>
			</datatable-filter>
			<datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
				:total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
				@on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
			</datatable>
			<CommonModal ref="createFormModal"  :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('new').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<create-form @createFormSuccess="createFormSuccess" v-if="formProcess == 'create'" />
				</template>
			</CommonModal>
			<CommonModal ref="updateFormModal"  :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('edit').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<update-form :formId="formId" @updateFormSuccess="updateFormSuccess" v-if="formProcess == 'update'" />
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"


// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";

// Pages
import UpdateForm from "@/modules/courseOutcome/pages/UpdateForm.vue"
import CreateForm from "@/modules/courseOutcome/pages/CreateForm.vue"

// Services
import CourseOutcome from "@/services/CourseOutcome";

// Others
import qs from 'qs'
export default {
	components: {
		AppLayout,
		Header,
        CreateForm,
        UpdateForm,
		HeaderMobile,
		DatatableFilter,
		Datatable,
		CommonModal,
		FacultySelectbox,
		DepartmentSelectbox,
		ParameterSelectbox,
		ProgramSelectbox,
		StatusSelectbox,
	},
	metaInfo() {
		return {
			title: this.$t('courses')
		}
	},
	data() {
		return {
			datatable: {
				isLoading: false,
				columns: [
					{
						label: '',
						field: 'buttons',
						html: true,
						sortable: false,
						tdClass: 'p-0 text-center w-40 align-middle',
						buttons: [
							{
								text: this.$t('edit'),
								class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
								permission: "courseoutcome_update",
								callback: (row) => {
									this.updateFormShow(row.id)
								}
							},
							{
								text: this.$t('delete'),
								class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
								permission: "courseoutcome_delete",
								callback: (row) => {
									this.formDelete(row.id)
								}
							}
						]
					},
					{
						label: this.toUpperCase('id'),
						field: 'id',
						hidden: true,
					},

					{
						label: this.toUpperCase('course_name') + ' TR',
						field: 'name',
						hidden: false,
						formatFn: (value, row) => {
                            return row.name_tr || row.name;
                        },
					},
					{
						label: this.toUpperCase('course_name') + ' EN',
						field: 'name_en',
						hidden: false,
					},
                    {
                        label: 'status',
                        field: 'status',
                        hidden: false,
                    },

				],
				rows: [],
				total: 0,
				filterStatus: true,
				showTable: false,
				queryParams: {
					filter: {},
					sort: '-id',
					page: 1,
					limit: 20
				}
			},
			formProcess: null,
			formId: null,
			form: {},
		}
	},
	created() {
		this.filterSet();
		this.formClear();
	},
	methods: {
		filterSet() {
			this.datatable.queryParams.filter = {
			}
		},
		filterClear() {
			this.filterSet()
			this.getRows();
		},
		filter() {
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		onPageChange(params) {
			this.datatable.queryParams.page = params.currentPage;
			this.getRows();
		},
		onPerPageChange(params) {
			this.datatable.queryParams.limit = params.currentPerPage;
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		changeColumn(key) {
			this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
		},
		onSortChange(params) {
			const sortType = params[0].type == 'desc' ? '-' : '';
			this.datatable.queryParams.sort = sortType + params[0].field
			this.getRows();
		},
		getRows() {
			this.datatable.showTable = true;
			this.datatable.isLoading = true;

			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};
			return CourseOutcome.getAll(config)
				.then((response) => {
					this.datatable.rows = response.data.data
					this.datatable.total = response.data.pagination.total

					this.datatable.rows.forEach((c) => c.status = c.status == 'a' ? this.$t('active') : this.$t('passive'))
				})
				.finally(() => {
					this.datatable.isLoading = false;
				});
		},
		// Clear
		formClear() {
			this.formId = null
			this.formProcess = null
		},

		// Create
		createFormShow() {
			this.formId = null
			this.formProcess = 'create'
			this.$refs.createFormModal.$refs.commonModal.show()
		},
		createFormSuccess() {
			this.$refs.createFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},

		// Update
		updateFormShow(id) {
			this.formId = id
			this.formProcess = 'update'
			this.$refs.updateFormModal.$refs.commonModal.show()
		},
		updateFormSuccess() {
			this.$refs.updateFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},

		formDelete(id) {
			this.$swal
				.fire({
					text: this.$t('are_you_sure_to_delete'),
					showCancelButton: true,
					confirmButtonText: this.$t('yes'),
					cancelButtonText: this.$t('no'),
				})
				.then((result) => {
					if (result.isConfirmed) {
						CourseOutcome.del(id)
							.then(response => {
								this.$toast.success(this.$t('api.' + response.data.message));
								this.getRows();
								this.formClear();
							})
							.catch(error => {
								this.$toast.error(this.$t('api.' + error.data.message));
							});
					}
				})
		},

	}
}
</script>

