<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('course')" class="mb-1">
                        <ValidationProvider name="course_id" rules="required" v-slot="{ errors }">
                            <course-auto-complete v-model="formData.course_id"
                                                  :validate-error="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                    <b-form-group :label="$t('name')" class="mt-2">
                        <ValidationProvider name="name_tr" v-slot="{ valid, errors }">
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input v-model="formData.name" :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{ valid, errors }">
                            <b-input-group prepend="EN">
                                <b-form-input v-model="formData.name_en" :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>

                    <ValidationProvider name="tyyc_basic_field_type" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('file_name')">
                            <parameter-selectbox v-model="formData.tyyc_basic_field_type" code="tyyc_basic_field_types"
                                                 :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input type="number" v-model="formData.rank">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                        <b-form-group :label="$t('parent')" class="mb-1">
                                <course-auto-complete v-model="formData.parent_id"
                                                      />
                        </b-form-group>
                    <ValidationProvider
                        name="status"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="formData.status"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import CourseOutcome from "@/services/CourseOutcome";

export default {
    components: {
        ParameterSelectbox,
        ProgramSelectbox,
        StaffAutoComplete,
        StatusSelectbox,
        MultiSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {
                course_id:null,
                name:null,
                name_en:null,
                rank:null,
                status:null,
                tyyc_basic_field_type:null,
                parent_id:null
            },
        }
    },
    methods: {
        async storeForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    CourseOutcome.store(this.formData)
                        .then(response => {
                            this.$emit('createFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
        }
    }
}
</script>
